.Navbar-logo {
  height: 10vmin;
}

.Navbar-main {
  background-color: #ffffff !important;
  font-weight: bold;
}

.Navbar-center {
  margin-left: 40px;
  width: 60%;
}

.Navbar-right {
  font-size: 14px;
  width: 25%;
}

.Navbar-items {
  margin-bottom: 5px;
}

.Navbar-link {
  margin-left: 10px;
}

.Navbar-phone {
  float: inline;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}