.AboutUs-banner {
    padding: 30px;
}

.AboutUs-right-desc {
    margin-top: 37px;
}

.AboutUs-text {
    margin-top: 20px;
}
