.Product-container {
    padding-top: 30px;
    padding-bottom: 30px;
}

.Product-item {
    margin: 20px;
    text-align: center;
}

.Product-image:hover {
    transform: rotateY(180deg);
}

.Product-image {
    /* background-color: #A1A1A1; */
    transition: transform 0.8s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.Product-text {
    background-color: #F9F9F9;
    padding-bottom: 20px;
}

.Product-row {
    margin-bottom: 30px;
}

.Product-image-size {
    width: 322px;
}
