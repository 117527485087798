.Footer-container {
    background-color: #1975B8;
}

.Footer-subscribe-newsletter {
    padding-top: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.Footer-dont-miss-out {
    padding-top: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.Footer-subscribe {
    padding-top: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.Footer-social {
    background-color: #C59968;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Footer-facebook {
    margin: 20px;
}

.Footer-instagram {
    margin: 20px;
}

.Footer-link {
    color: #000000;
}

/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                       We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
                       
#mc_embed_signup {
    background: #fff;
    clear: left;
    font: 14px Helvetica, Arial, sans-serif;
    width: 600px;
}

